<template>
  <section class="partner part">
    <Image v-view :src="payload.bild" class="image hidden md:block animate in-bottom" />
    <div v-view class="constrain animate in-bottom">
      <div class="row-12">
        <div class="col-12 md:col-3 md:offset-1">
          <h2 v-html="payload.titel" />
          <span class="subtitle" v-html="payload.untertitel" />
        </div>
        <div class="col-12 md:col-10 md:offset-1">
          <slider
            v-slot="{ item }"
            class="slider"
            :items="payload.partner"
          >
            <partner-card
              class="card h-full"
              :image="item.bild"
              :website="item.website"
              :title="item.name"
              :text="item.beschreibung"
              :button-text="item.buttontext"
              :button-link="item.buttonlink"
            />
          </slider>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue';
import PartnerCard from '@/components/utils/PartnerCard.vue';

export default {
  components: { PartnerCard },
  props: { payload: { type: Object, default: () => ({}) } },
  setup(props) {
    return {
      showBlogLink: computed(() => props.payload.optionen.includes('showBlogLink')),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.partner {
  @apply bg-primary;
  @apply text-dark-orange;

  @include responsive('margin-top', px(0), px(200));
  @include responsive('padding', px(60) 0 px(40) 0, px(141) 0 px(117) 0);

  h2 {
    margin-bottom: 1rem;
  }

  .subtitle {
    display: inline-block;
    @include responsive('margin-bottom', px(45), px(95));
  }

  &.orange {
    @apply bg-primary;
    @apply text-dark-orange;
  }

  .image {
    position: absolute;
    bottom: calc(100% + #{px(200)});
    left: calc(50% + var(--col-width) * 3.5);
    width: calc(var(--col-width) * 3);
    transform: translate(-50%, 50%);
    z-index: 100;
  }
}

h2 {
  @include responsive('margin-bottom', px(60), px(81));
}

.slider {
  overflow: visible;
}

.card {
  @apply text-dark-gray;
}
</style>
