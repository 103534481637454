<template>
  <card class="blog-card" :image="image">
    <div class="content">
      <span class="website">
        {{ website }}
      </span>
      <h3 class="mb-8 title" v-html="title" />
      <div class="mb-8 text" v-html="text" />
      <Go :to="buttonLink" class="link btn mt-auto">
        {{ buttonText }}
      </Go>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    image: { type: Object },
    title: { type: String },
    text: { type: String },
    website: { type: String },
    buttonText: { type: String },
    buttonLink: { type: String },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.content {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  flex: 1;
  margin-top: -5%;
  @include responsive('padding', px(16) px(12), px(18) px(26) px(18) px(26));
}

.title {
  @apply text-dark;
}

.website {
  @apply text-base;
  @apply font-bold;
  display: block;
  margin-left: auto;
  width: fit-content;
  margin-bottom: px(18);
  line-height: px(20);
}

.text {
  @apply text-base;
  line-height: px(22);
}

.link {
  @include responsive('align-self', flex-end, flex-start);
}
</style>
